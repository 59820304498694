import Image, { StaticImageData } from "next/image";
import { getNextImageSizeProps } from "./helpers";
import { ImagePlaceholder } from "./types";
import ArtworkPlaceholder from "@/assets/images/artworkPlaceholder.jpg";

export interface SizeProps {
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  grid?: boolean;
  aspectRatio?: boolean;
  dynamic?: boolean;
}

export interface Props extends SizeProps {
  src: string | StaticImageData;
  placeholder?: ImagePlaceholder | null;
  displayDefaultPlaceholder?: boolean;
  alt: string;
  className?: string;
  priority?: boolean;
  onClick?: () => any;
}

export default function EndemicImage({
  src,
  alt,
  className,
  priority,
  onClick,
  placeholder,
  displayDefaultPlaceholder = false,
  ...sizeProps
}: Props) {
  return (
    <Image
      src={src}
      alt={alt}
      unoptimized
      className={className}
      priority={priority}
      onClick={onClick}
      placeholder={placeholder || displayDefaultPlaceholder ? "blur" : "empty"}
      blurDataURL={
        placeholder
          ? placeholder
          : displayDefaultPlaceholder
            ? ArtworkPlaceholder.src
            : undefined
      }
      {...getNextImageSizeProps(sizeProps)}
    />
  );
}
